module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/Users/mattfeng/datasets-db/web/node_modules/gatsby-remark-images","id":"8ffb5780-a728-521c-965b-a274bf77b5c4","name":"gatsby-remark-images","version":"6.6.0","modulePath":"/Users/mattfeng/datasets-db/web/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":600,"showCaptions":true},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/Users/mattfeng/datasets-db/web/node_modules/gatsby-remark-copy-linked-files","id":"cf104666-f93f-5e9d-9c2e-1bd9003e1e14","name":"gatsby-remark-copy-linked-files","version":"5.6.0","modulePath":"/Users/mattfeng/datasets-db/web/node_modules/gatsby-remark-copy-linked-files/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/Users/mattfeng/datasets-db/web/node_modules/gatsby-remark-highlight-code","id":"a6a1897c-9428-555d-9922-6dc8b8f9309a","name":"gatsby-remark-highlight-code","version":"3.2.0","modulePath":"/Users/mattfeng/datasets-db/web/node_modules/gatsby-remark-highlight-code/index.js","pluginOptions":{"plugins":[],"theme":"solarized-light"},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"remarkPlugins":[[null,{"strict":"ignore","macros":{}}]],"rehypePlugins":[[null,{"macros":{}}]],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/mattfeng/datasets-db/web","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":600,"showCaptions":true},
    }]
